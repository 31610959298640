import { Button, Grid, TextField, Typography } from "@mui/material";
import * as React from "react";
import { Item, showSnackbar } from "../App";
import { submitSignup } from "../common/api";

export default function Signup(props) {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [businessName, setBusinessName] = React.useState("");
  const [businessAddress, setBusinessAddress] = React.useState("");
  const updateUsername = (e) => setUsername(e.target.value);
  const updatePassword = (e) => setPassword(e.target.value);
  const updateBusinessName = (e) => setBusinessName(e.target.value);
  const updateBusinessAddress = (e) => setBusinessAddress(e.target.value);

  const submitSignupForm = async () => {
    if (!username || !password || !businessName || !businessAddress) {
      showSnackbar("info", "Please enter the required details marked with *");
      return;
    }
    const isSubmitted = await submitSignup(
      username,
      password,
      businessName,
      businessAddress
    );
    console.log("Is Submitted? ", isSubmitted);
    if (isSubmitted) {
      showSnackbar(
        "success",
        `Your details have been submitted successfully! ` +
          `You'll receive a confirmation link in an email in your inbox ` +
          `at ${username}. Please click on it to complete the registration ` +
          `process.`,
        true
      );
    } else {
      showSnackbar("error", "Registration failed. Please try again.", true);
    }
  };

  return (
    <Grid container alignItems="center" justify="center">
      <Grid item xs={12}>
        <Item style={{ padding: "2em" }}>
          <div align="center" style={{ width: "100%" }}>
            <form
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                submitSignupForm();
              }}
            >
              <Grid item xs={12}>
                <Typography
                  id="header-login"
                  variant="h5"
                  component="div"
                  sx={{ flexGrow: 1 }}
                  style={{ color: "#0047CC", fontWeight: "bold" }}
                >
                  Welcome, Buyer!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  id="header-login-sub"
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1 }}
                  style={{ fontWeight: "bold", marginTop: "2em" }}
                >
                  Register with Sonic by entering your business details below.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  type="email"
                  margin="normal"
                  id="username"
                  label="Username"
                  placeholder="youremail@yourcompany.com"
                  value={username}
                  onChange={updateUsername}
                  style={{ width: "50%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  type="password"
                  margin="normal"
                  id="password"
                  label="Password"
                  placeholder="your-scale-password-here"
                  value={password}
                  onChange={updatePassword}
                  style={{ width: "50%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  margin="normal"
                  id="business-name"
                  label="Business Name"
                  placeholder="Your Business's Name Here"
                  value={businessName}
                  onChange={updateBusinessName}
                  style={{ width: "50%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  margin="normal"
                  type="text"
                  id="business-address"
                  label="Business Address"
                  placeholder="Your Business's Complete Address Here"
                  value={businessAddress}
                  onChange={updateBusinessAddress}
                  style={{ width: "50%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    backgroundColor: "#0047CC",
                    margin: "1em",
                    width: "25%",
                    height: "3em",
                  }}
                >
                  Register
                </Button>
              </Grid>
            </form>
          </div>
        </Item>
      </Grid>
    </Grid>
  );
}
