import { Button, Grid, TextField, Typography } from "@mui/material";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { checkHeaderAuth, Item, showSnackbar } from "../App";
import { submitLogin } from "../common/api";

export default function Login(props) {
  const navigate = useNavigate();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const updateUsername = (e) => setUsername(e.target.value);
  const updatePassword = (e) => setPassword(e.target.value);
  const submitLoginForm = async () => {
    if (!username || !password) {
      showSnackbar("info", "Please enter username and password.");
      return;
    }
    const isLoggedIn = await submitLogin(username, password);
    if (isLoggedIn) {
      checkHeaderAuth();
      const invoiceUUID = localStorage.getItem("invoice_uuid");
      if (invoiceUUID) {
        navigate("/invoice/" + invoiceUUID);
      } else {
        showSnackbar("success", "Login successful!");
      }
    } else {
      showSnackbar("error", "Login failed. Please try again.");
    }
  };

  return (
    <Grid container alignItems="center" justify="center">
      <Grid item xs={12}>
        <Item style={{ padding: "2em" }}>
          <div align="center" style={{ width: "100%" }}>
            <form
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                submitLoginForm();
              }}
            >
              <Grid item xs={12}>
                <Typography
                  id="header-login"
                  variant="h5"
                  component="div"
                  sx={{ flexGrow: 1 }}
                  style={{ color: "#0047CC", fontWeight: "bold" }}
                >
                  Welcome to Sonic Finance
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  id="header-login-sub"
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1 }}
                  style={{ fontWeight: "bold", marginTop: "2em" }}
                >
                  Login with your Sonic username &amp; password. Not yet
                  registered? <Link to="/signup">Register here</Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  type="email"
                  margin="normal"
                  id="username"
                  label="Username"
                  placeholder="youremail@yourcompany.com"
                  value={username}
                  onChange={updateUsername}
                  style={{ width: "50%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  type="password"
                  margin="normal"
                  id="password"
                  label="Password"
                  placeholder="your-scale-password-here"
                  value={password}
                  onChange={updatePassword}
                  style={{ width: "50%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    backgroundColor: "#0047CC",
                    margin: "1em",
                    width: "25%",
                    height: "3em",
                  }}
                >
                  Login
                </Button>
              </Grid>
            </form>
          </div>
        </Item>
      </Grid>
    </Grid>
  );
}
