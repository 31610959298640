import axios from "axios";

export const getInvoiceDetails = async (invoice_uuid) => {
  const authToken = localStorage.getItem("sme_jwt_token");
  if (!authToken) {
    throw new Error("INVALID_AUTH_TOKEN");
  }
  const res = await axios.get(
    `${process.env.REACT_APP_SCALE_API_BASE_URL}` +
      `/invoices/invoice/${invoice_uuid}`,
    { headers: { Authorization: authToken } }
  );
  if (res.status === 401) {
    localStorage.removeItem("sme_jwt_token");
    throw new Error("INVALID_AUTH_TOKEN");
  }
  return JSON.parse(JSON.stringify(res.data));
};

export const submitCardDetails = async (
  stripePaymentMethodID,
  smeUUID,
  invoiceUUID
) => {
  try {
    const authToken = localStorage.getItem("sme_jwt_token");
    if (!authToken) {
      throw new Error("INVALID_AUTH_TOKEN");
    }
    const res = await axios.post(
      `${process.env.REACT_APP_SCALE_API_BASE_URL}` +
        `/loan-repayment/card-details/submit`,
      {
        country: "US",
        currency: "usd",
        stripe_payment_method_id: stripePaymentMethodID,
        sme_uuid: smeUUID,
        invoice_uuid: invoiceUUID,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    return res.status === 200;
  } catch (e) {
    console.error("Error in submitCardDetails:", e.stack);
    return false;
  }
};

export const submitACHDetails = async (
  accountHolderName,
  routingNumber,
  accountNumber,
  smeUUID,
  invoiceUUID
) => {
  try {
    const authToken = localStorage.getItem("sme_jwt_token");
    if (!authToken) {
      throw new Error("INVALID_AUTH_TOKEN");
    }
    const res = await axios.post(
      `${process.env.REACT_APP_SCALE_API_BASE_URL}` +
        `/loan-repayment/ach-details/submit`,
      {
        country: "US",
        currency: "usd",
        account_holder_type: "individual",
        account_holder_name: accountHolderName,
        routing_number: routingNumber,
        account_number: accountNumber,
        sme_uuid: smeUUID,
        invoice_uuid: invoiceUUID,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    return res.status === 200;
  } catch (e) {
    console.error("Error in submitACHDetails:", e.stack);
    return false;
  }
};

export const setInvoicePaymentMethod = async (
  paymentMethodID,
  paymentMethodType,
  smeUUID,
  invoiceUUID
) => {
  try {
    const authToken = localStorage.getItem("sme_jwt_token");
    if (!authToken) {
      throw new Error("INVALID_AUTH_TOKEN");
    }
    const res = await axios.put(
      `${process.env.REACT_APP_SCALE_API_BASE_URL}` +
        `/loan-repayment/invoice-payment-method`,
      {
        payment_method_id: paymentMethodID,
        payment_method_type: paymentMethodType,
        sme_uuid: smeUUID,
        invoice_uuid: invoiceUUID,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    return res.status === 200;
  } catch (e) {
    console.error("Error in setInvoicePaymentMethod:", e.stack);
    return false;
  }
};

export const getExistingPaymentMethods = async (smeUUID) => {
  try {
    const authToken = localStorage.getItem("sme_jwt_token");
    if (!authToken) {
      throw new Error("INVALID_AUTH_TOKEN");
    }
    const existingPaymentMethods = [];
    const achListRes = await axios.get(
      `${process.env.REACT_APP_SCALE_API_BASE_URL}` +
        `/loan-repayment/ach-details`,
      {
        headers: {
          Authorization: authToken,
        },
      }
    );
    if (achListRes.status !== 200) {
      throw new Error("Unable to obtain existing ACH payment methods.");
    }
    const cardListRes = await axios.get(
      `${process.env.REACT_APP_SCALE_API_BASE_URL}` +
        `/loan-repayment/card-details`,
      {
        headers: {
          Authorization: authToken,
        },
      }
    );
    if (cardListRes.status !== 200) {
      throw new Error("Unable to obtain existing Card payment methods.");
    }

    const achList = JSON.parse(
      JSON.stringify(achListRes.data.ach_details_list)
    );
    achList.forEach((ach) => {
      existingPaymentMethods.push({
        paymentMethodID: ach.id,
        paymentMethodType: "ACH",
        endingIn: ach.account_number,
      });
    });

    const cardList = JSON.parse(
      JSON.stringify(cardListRes.data.card_details_list)
    );
    cardList.forEach((card) => {
      existingPaymentMethods.push({
        paymentMethodID: card.id,
        paymentMethodType: "CARD",
        endingIn: card.pan_tail,
      });
    });
    return existingPaymentMethods;
  } catch (e) {
    console.error("Error in getExistingPaymentMethods:", e.stack);
    return null;
  }
};

export const submitLogin = async (username, password) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_SCALE_API_BASE_URL}/auth/login/buyer`,
      {
        username: username,
        password: password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status === 200) {
      const authTokenRes = JSON.parse(JSON.stringify(res.data));
      localStorage.setItem("sme_jwt_token", authTokenRes.auth_token);
      return true;
    }
    return false;
  } catch (e) {
    console.error("Error in submitLogin:", e.stack);
    return false;
  }
};

export const submitSignup = async (
  username,
  password,
  businessName,
  businessAddress
) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_SCALE_API_BASE_URL}/auth/signup/buyer`,
      {
        username: username,
        password: password,
        business_name: businessName,
        business_address: businessAddress,
        email: username,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Response Status is Success?: ", res.status === 200);
    return res.status === 200;
  } catch (e) {
    console.error("Error in submitSignup:", e.stack);
    return false;
  }
};
