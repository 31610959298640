import * as React from "react";
import moment from "moment";
import TextField from "@mui/material/TextField";
import CheckCircle from "@mui/icons-material/CheckCircle";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import * as Stripe from "@stripe/stripe-js";
import { Item, showSnackbar } from "./../App";
import { useNavigate, useParams } from "react-router-dom";
import {
  getInvoiceDetails,
  submitCardDetails,
  submitACHDetails,
  setInvoicePaymentMethod,
  getExistingPaymentMethods,
} from "../common/api";
import { useEffect } from "react";

export default function Invoice(props) {
  const urlParams = useParams();
  const invoiceUUID = urlParams.invoice_uuid;
  localStorage.setItem("invoice_uuid", invoiceUUID);
  const navigate = useNavigate();

  const [smeUUID, setSMEUUID] = React.useState("");
  const [invoiceDetails, setInvoiceDetails] = React.useState({});
  const [accountHolderName, setAccountHolderName] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [routingNumber, setRoutingNumber] = React.useState("");
  const [existingPaymentMethods, setExistingPaymentMethods] = React.useState(
    []
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState("");
  const updateAccountHolderName = (e) => setAccountHolderName(e.target.value);
  const updateAccountNumber = (e) => setAccountNumber(e.target.value);
  const updateRoutingNumber = (e) => setRoutingNumber(e.target.value);
  const updateSelectedPaymentMethod = (e) =>
    setSelectedPaymentMethod(e.target.value);

  useEffect(() => {
    const getExistingPaymentMethodsFromAPI = async (smeUUID) => {
      const existingPMs = await getExistingPaymentMethods(smeUUID);
      setExistingPaymentMethods(existingPMs);
    };

    const invoiceDetailsFromAPI = async (invoiceUUID) => {
      if (!invoiceUUID) {
        console.warn("Invoice UUID is empty.");
        return;
      }
      console.log(`Invoice UUID: ${invoiceUUID}`);
      try {
        const invoiceDetails = await getInvoiceDetails(invoiceUUID);
        setInvoiceDetails(invoiceDetails);
        setSMEUUID(invoiceDetails?.invoice_payload?.invoice.sme_uuid);
        getExistingPaymentMethodsFromAPI(smeUUID);
      } catch (err) {
        navigate("/login");
      }
    };
    invoiceDetailsFromAPI(invoiceUUID);
  }, [invoiceUUID, navigate, smeUUID]);

  const submitACHDetailsForm = async () => {
    if (!accountHolderName || !routingNumber || !accountNumber) {
      showSnackbar("info", "Please fill in the mandatory fields.");
      return;
    }
    console.log(
      `Submitting ACH Details:\nAcNo:${accountNumber}, ` +
        `RNo: ${routingNumber}, acHName: ${accountHolderName}`
    );
    const isSubmitted = await submitACHDetails(
      accountHolderName,
      routingNumber,
      accountNumber,
      smeUUID,
      invoiceUUID
    );
    if (isSubmitted) {
      showSnackbar("success", "Bank Account details submitted successfully.");
    } else {
      showSnackbar("error", "Bank Account details submission failure.");
    }
  };

  let submitCardDetailsForm = () => {
    Promise.resolve(alert("Stripe is still loading."));
  };

  const setInvoicePaymentMethodForm = async () => {
    if (!selectedPaymentMethod || selectedPaymentMethod === "{}") {
      showSnackbar("info", "Please select a payment method to proceed.");
      return;
    }
    console.log("Setting selected payment method:", selectedPaymentMethod);
    const parsed = JSON.parse(selectedPaymentMethod);
    const isSubmitted = await setInvoicePaymentMethod(
      parsed.paymentMethodID,
      parsed.paymentMethodType,
      smeUUID,
      invoiceUUID
    );
    if (isSubmitted) {
      showSnackbar("success", "Invoice payment method updated successfully.");
    } else {
      showSnackbar("error", "Invoice payment method updation failure.");
    }
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  // eslint-disable-next-line
  const stripe = Stripe.loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  ).then((stripe) => {
    const elements = stripe.elements();
    const cardElement = elements.create("card");
    cardElement.mount("#card-element");

    submitCardDetailsForm = async () => {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        // Display error.message in your UI.
        console.error(error.message);
        showSnackbar(
          "error",
          "There was a problem while adding your card. Please try again."
        );
        return;
      }
      // You have successfully created a new PaymentMethod
      console.log("Created payment method: " + paymentMethod.id);

      const isSubmitted = submitCardDetails(
        paymentMethod.id,
        smeUUID,
        invoiceUUID
      );

      if (isSubmitted) {
        showSnackbar("success", "Card saved successfully.");
      } else {
        showSnackbar("error", "Failure in submitting card details.");
      }
    };
  });

  console.log(`Invoice Details (render): ${JSON.stringify(invoiceDetails)}`);
  console.log(
    `Existing Payment Methods (render): ${JSON.stringify(
      existingPaymentMethods
    )}`
  );
  const invoice = invoiceDetails?.invoice_payload?.invoice;

  return (
    <Grid container alignItems="stretch" justify="center">
      <Grid item xs={6}>
        <Item>
          <div align="center" style={{ width: "100%" }}>
            <form
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                submitACHDetailsForm();
              }}
            >
              <Typography
                id="header-invoice-details"
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
                style={{ color: "#0047CC", fontWeight: "bold" }}
              >
                Invoice #{invoice?.invoice_number}
              </Typography>
              <Typography
                id="invoice-sme-name"
                variant="h8"
                align="left"
                component="div"
                sx={{ flexGrow: 1 }}
                style={{ fontWeight: "bold" }}
              >
                {"Seller Inc"}
              </Typography>
              <Typography
                id="invoice-sme-address"
                variant="h8"
                align="left"
                component="div"
                sx={{ flexGrow: 1 }}
              >
                {"404 Seller, Seller City, CA 43002"}
              </Typography>
              <Typography
                id="invoice-billing-name"
                variant="h8"
                align="left"
                component="div"
                sx={{ flexGrow: 1 }}
                style={{ marginTop: "0.5em" }}
              >
                <p
                  style={{
                    marginRight: "0.1em",
                    fontWeight: "bold",
                    display: "inline",
                  }}
                >
                  Bill To:
                </p>
                <Grid container>
                  <Grid item xs={8} style={{ fontWeight: "bold" }} align="left">
                    {invoice?.customer_name}
                  </Grid>
                  <Grid item xs={4} align="right">
                    <p
                      style={{
                        marginRight: "0.1em",
                        fontWeight: "bold",
                        display: "inline",
                      }}
                    >
                      Date:
                    </p>
                    {moment(invoice?.transaction_date).format("DD-MMM-YYYY")}
                  </Grid>
                  <Grid item xs={8} align="left">
                    <p
                      style={{
                        marginRight: "0.1em",
                        fontWeight: "bold",
                        display: "inline",
                      }}
                    >
                      Name:
                    </p>
                    {invoice?.customer_name}
                  </Grid>
                  <Grid item xs={4} align="right">
                    <p
                      style={{
                        marginRight: "0.1em",
                        fontWeight: "bold",
                        display: "inline",
                      }}
                    >
                      Payment Terms:
                    </p>
                    {"Net-60"}
                  </Grid>
                  <Grid item xs={8} align="left">
                    <p
                      style={{
                        marginRight: "0.1em",
                        fontWeight: "bold",
                        display: "inline",
                      }}
                    >
                      Address:
                    </p>
                    {invoice?.customer_address}
                  </Grid>
                  <Grid item xs={4} align="right">
                    <p
                      style={{
                        marginRight: "0.1em",
                        fontWeight: "bold",
                        display: "inline",
                      }}
                    >
                      Due Date:
                    </p>
                    {moment(invoice?.original_due_date).format("DD-MMM-YYYY")}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    align="center"
                    style={{ marginTop: "2em" }}
                  >
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ maxWidth: "100%" }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{ fontWeight: "bold" }}
                            >
                              Item
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontWeight: "bold" }}
                            >
                              Qty
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontWeight: "bold" }}
                            >
                              Rate
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontWeight: "bold" }}
                            >
                              Amount
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {invoice &&
                            invoice.invoice_line_items.invoice_line_items
                              .length > 0 &&
                            invoice.invoice_line_items.invoice_line_items.map(
                              (row) => (
                                <TableRow
                                  key={row.name}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    {row.name}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.quantity || '-'}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.amount && row.quantity ? formatter.format(
                                      row.amount / row.quantity
                                    ) : '-' }
                                  </TableCell>
                                  <TableCell align="center">
                                    USD {formatter.format(row.amount)}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          {invoice &&
                            invoice.invoice_line_items.invoice_line_items
                              .length === 0 && (
                              <TableRow>
                                <TableCell colSpan={4} align="center">
                                  No individual line items for this invoice.
                                </TableCell>
                              </TableRow>
                            )}
                        </TableBody>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="right"
                              colSpan={3}
                              style={{ fontWeight: "bold" }}
                            >
                              Total
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontWeight: "bold" }}
                            >
                              USD{" "}
                              {formatter.format(
                                invoice?.invoice_line_items?.total_amount
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              align="right"
                              colSpan={3}
                              style={{ fontWeight: "bold" }}
                            >
                              Tax
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontWeight: "bold" }}
                            >
                              USD{" "}
                              {formatter.format(
                                invoice?.invoice_line_items?.total_tax
                              )}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    align="center"
                    style={{ marginTop: "2em" }}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.2em",
                        display: "inline",
                      }}
                    >
                      Additional Notes:
                    </p>
                    <List>
                      <ListItem disablePadding>
                        <ListItemIcon style={{ color: "#7CFC00" }}>
                          <CheckCircle></CheckCircle>
                        </ListItemIcon>
                        <ListItemText>
                          Payment to be scheduled using the option mentioned
                        </ListItemText>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemIcon style={{ color: "#7CFC00" }}>
                          <CheckCircle></CheckCircle>
                        </ListItemIcon>
                        <ListItemText>
                          Services provided delivered on{" "}
                          {moment(invoice?.transaction_date).format(
                            "DD-MMM-YYYY"
                          )}
                          .
                        </ListItemText>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemIcon style={{ color: "#7CFC00" }}>
                          <CheckCircle></CheckCircle>
                        </ListItemIcon>
                        <ListItemText>
                          In case of questions, please email us at{" "}
                          <a href={"mailto:" + invoice?.customer_email}>
                            {invoice?.customer_email}
                          </a>
                        </ListItemText>
                      </ListItem>
                    </List>

                    <p
                      style={{
                        marginTop: "1em",
                        fontWeight: "bold",
                        fontSize: "1.2em",
                        color: "#0047CC",
                      }}
                    >
                      We thank you for your business and look forward to serving
                      you again.
                    </p>
                  </Grid>
                </Grid>
              </Typography>
            </form>
          </div>
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item style={{ padding: "2em" }}>
          <div align="center" style={{ width: "100%" }}>
            <form
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                setInvoicePaymentMethodForm();
              }}
            >
              <Typography
                id="header-card-details"
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
                style={{ fontWeight: "bold" }}
              >
                Pay with a saved payment method
              </Typography>
              {!existingPaymentMethods && (
                <Typography
                  id="message-no-existing-pm"
                  variant="h8"
                  component="div"
                  sx={{ flexGrow: 1 }}
                  style={{
                    marginTop: "1em",
                  }}
                >
                  No existing payment methods associated with your account.
                </Typography>
              )}
              {existingPaymentMethods && (
                <FormControl
                  fullWidth
                  style={{ marginTop: "1em", alignItems: "center" }}
                >
                  <RadioGroup
                    aria-labelledby="existing-payment-methods-aria"
                    name="existing-payment-methods-btn-group"
                    value={selectedPaymentMethod}
                    onChange={updateSelectedPaymentMethod}
                  >
                    {existingPaymentMethods?.map((pm) => (
                      <FormControlLabel
                        key={pm.paymentMethodType + "_" + pm.paymentMethodID}
                        value={JSON.stringify(pm)}
                        control={<Radio />}
                        label={`${
                          pm.paymentMethodType === "ACH"
                            ? "Bank account"
                            : "Card"
                        } ending ${pm.endingIn}`}
                      />
                    ))}
                  </RadioGroup>
                  <Button
                    variant="contained"
                    type="submit"
                    style={{ backgroundColor: "#0047CC", marginTop: "1em" }}
                  >
                    Schedule Payment
                  </Button>
                </FormControl>
              )}
            </form>
          </div>
        </Item>
        <Item>
          <div align="center" style={{ width: "100%" }}>
            <form
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                submitCardDetailsForm();
              }}
            >
              <Typography
                id="header-card-details"
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
                style={{ fontWeight: "bold" }}
              >
                Pay by Credit/Debit Card
              </Typography>
              <div className="stripe-card-details" id="card-element"></div>
              <Button
                id="card-button"
                variant="contained"
                type="submit"
                style={{ backgroundColor: "#0047CC" }}
              >
                Schedule Payment
              </Button>
            </form>
          </div>
        </Item>
        <Item style={{ padding: "2em" }}>
          <div align="center" style={{ width: "100%" }}>
            <form
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                submitACHDetailsForm();
              }}
            >
              <Typography
                id="header-ach-details"
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
                style={{ fontWeight: "bold" }}
              >
                Pay using ACH/Bank Transfer
              </Typography>
              <TextField
                fullWidth
                required
                margin="normal"
                id="account-number"
                label="Account Number"
                placeholder="Account Number"
                value={accountNumber}
                onChange={updateAccountNumber}
              />
              <TextField
                fullWidth
                required
                margin="normal"
                id="routing-number"
                label="Routing Number"
                placeholder="Routing Number"
                value={routingNumber}
                onChange={updateRoutingNumber}
              />
              <TextField
                fullWidth
                required
                margin="normal"
                id="account-holder-name"
                label="Account Holder Name"
                placeholder="Account Holder Name"
                value={accountHolderName}
                onChange={updateAccountHolderName}
              />
              <Button
                variant="contained"
                type="submit"
                style={{ backgroundColor: "#0047CC" }}
              >
                Schedule Payment
              </Button>
            </form>
          </div>
        </Item>
      </Grid>
    </Grid>
  );
}
