import * as React from "react";
import "./App.css";
import logo from "./logo.png";
import { Grid, Snackbar, Alert, styled, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Invoice from "./components/Invoice";
import Login from "./components/Login";
import Signup from "./components/Signup";
import { useEffect } from "react";

export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  textAlign: "center",
  verticalAlign: "middle",
  color: theme.palette.text.secondary,
}));

export let closeSnackbar = null,
  showSnackbar = null,
  checkHeaderAuth = null;

export default function App() {
  const [snackbarState, setSnackbarState] = React.useState({});
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  closeSnackbar = (e) => {
    setSnackbarState({ ...snackbarState, isSnackBarOpen: false });
  };

  showSnackbar = (severity, message, autoHideDisabled = false) => {
    setSnackbarState({
      ...snackbarState,
      isSnackBarOpen: true,
      severity: severity,
      message: message,
      autoHideDuration: autoHideDisabled ? null : 5000,
    });
  };

  checkHeaderAuth = () => {
    setIsLoggedIn(!!localStorage.getItem("sme_jwt_token"));
  };

  const handleLogout = () => {
    localStorage.removeItem("sme_jwt_token");
    window.location.reload();
  };

  useEffect(() => {
    checkHeaderAuth();
  });

  return (
    <Grid
      container
      spacing={4}
      paddingX={"4em"}
      paddingY={"1em"}
      alignItems="stretch"
      justify="center"
    >
      {/* Header Logo */}
      <Grid item xs={12} style={{ paddingBottom: "1em" }}>
        <Item>
          <img src={logo} style={{ height: "7em" }} alt="logo" />
          {isLoggedIn && (
            <Button
              onClick={handleLogout}
              style={{
                fontWeight: "bold",
                fontSize: "1em",
                float: "right",
                paddingTop: "1em",
              }}
            >
              Logout
            </Button>
          )}
        </Item>
      </Grid>

      {/* Application Routes */}
      <Grid item xs={12} style={{ paddingBottom: "1em" }}>
        <BrowserRouter>
          <Routes>
            <Route path="/invoice/:invoice_uuid" element={<Invoice />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/" element={<Navigate to="/login" />} />
          </Routes>
        </BrowserRouter>
      </Grid>

      {/* Application Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarState.isSnackBarOpen}
        autoHideDuration={snackbarState.autoHideDuration}
        onClose={closeSnackbar}
      >
        <Alert
          onClose={snackbarState.closeSnackbar}
          severity={snackbarState.severity}
          sx={{ width: "100%" }}
        >
          <div className="snackbar-message">{snackbarState.message}</div>
        </Alert>
      </Snackbar>
    </Grid>
  );
}
